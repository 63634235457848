import React from 'react';
import styled from 'styled-components';
import { useAgile } from '@agile-ts/react';
import PageLayout from '../../components/layout/PageLayout';
import { useWindowSize } from '../../hooks/useWindowSize';
import { ui } from '../../../core';
import Clouds from '../gamelab/components/HeaderView/components/Clouds';
import LinesBackground from '../../components/primitive/background/LinesBackground';

const TermsOfUseScreen: React.FC = () => {
  const slowPerformance = useAgile(ui.SLOW_PERFORMANCE);
  const { windowWidth } = useWindowSize();

  return (
    <PageLayout
      meta={{ title: 'TERMS OF USE' }}
      shouldRenderFooter={false}
      shouldRenderNavbar={false}
    >
      <Container>
        {!slowPerformance && windowWidth > ui.WIDTH_BREAK_POINTS[1] && (
          <Clouds />
        )}
        {/* Center */}
        <CenterContent>
          <TitleContainer linesCount={10}>
            <Title>Reli-Birds</Title>
          </TitleContainer>
        </CenterContent>

        <Text>
          <h1>TERMS OF USE</h1>
          <p>Updated July 26, 2024</p>
          <ol>
            <li>
              This Agreement governs your use of the interactive game RELI-BIRDS
              (currently located at https://relibirds.newrelic-es.com/) provided
              by New Relic, Inc. (“New Relic” or “we”), including any content,
              functionality, products, or services offered on or through the
              interactive game or related websites (together, the “Game”).
              PLEASE READ THIS AGREEMENT CAREFULLY BEFORE YOU START TO USE THE
              GAME. BY USING THE GAME, YOU ACCEPT AND AGREE TO BE BOUND AND
              ABIDE BY THIS AGREEMENT. IF YOU DO NOT WANT TO AGREE TO THIS
              AGREEMENT, YOU MUST NOT USE THE GAME.
            </li>
            <li>
              By using or accessing the Game or related websites, clicking on
              the “Click to Start” button, entering information into the “Enter
              your name to be on the Leaderboard” box, or engaging in similar
              activity: (a) you agree to be bound by the terms of service
              herein; (b) you represent that you are of legal age to form a
              binding contract with New Relic; and (c) you represent that you
              have the authority to enter into this Agreement personally, or on
              behalf of the company or other organization you have named and
              that you have the authority to bind that organization to this
              Agreement. This Agreement applies to the Game only and does not
              apply to any other New Relic product or service offering.
            </li>
            <li>
              We may update this Agreement from time to time, at our sole
              discretion, and any changes to this Agreement will become
              effective immediately. If you do not agree to any changes, your
              sole remedy is to stop using the Game.
            </li>
            <li>
              During the term of this Agreement, New Relic grants to you the
              right to use and access the Game for entertainment purposes only,
              provided that you adhere to the terms of this Agreement. We may
              modify or discontinue the Game, including any portions of the
              Game, at any time. Similarly, we may stop, suspend, or modify the
              Game and related services at any time without prior notice to you.
              New Relic reserves the right, at its sole discretion, to disallow
              any persons, including you, from using the Game for any reason,
              including if it is determined that such use of the Game is not
              lawful or not in accordance with any rules of the Game set out by
              New Relic. Notwithstanding any other provisions of this Agreement,
              New Relic may, at its sole discretion, remove you or any other
              persons from the Leaderboard for any reason whatsoever and may
              refuse to give to any persons any incentives, rewards, or prizes
              related to any use of the Game.
            </li>
            <li>
              If you input information into the “Enter your name to be on the
              Leaderboard” box associated with the Game or engage in similar
              activity, New Relic may use, collect, and process this information
              (“Customer Data”) in accordance with the New Relic General Data
              Privacy Notice (currently located at
              https://newrelic.com/privacy). If you provide any Customer Data to
              New Relic, you thereby grant New Relic a non-exclusive, worldwide
              right to use, copy, store, transmit, display, modify, and create
              derivative works of the Customer Data, to the extent necessary to
              manage, improve, and provide the Game and related services.
            </li>
            <li>
              You will not (and will not permit anyone else to) do any of the
              following: (a) use the Game to develop a similar or competing
              product or service; (b) reverse engineer, decompile, disassemble,
              or seek to access the source code, algorithms, or non-public APIs
              to the Game or any related features, except to the extent
              expressly permitted by law (and then only with prior notice to New
              Relic); (c) modify or create derivative works of the Game or copy
              any element of or related features with the Game; (d) publish
              benchmarks or performance information about the Game; (e) perform
              any security integrity review, penetration test, load test, denial
              of service simulation, or vulnerability scan on New Relic; (f)
              provide access to or sublicense the Game to a third party; (g) use
              the Game on behalf of, or to provide any product or service to,
              third parties; or (h) input any Customer Data into the game that
              is obscene, derogatory, libelous, or otherwise unlawful or that
              violates any third-party rights (including intellectual property,
              publicity, or privacy rights). To ensure compliance with these
              requirements, New Relic may monitor or review use of the Game and
              investigate suspected violations of this Agreement.
            </li>
            <li>
              You must not send any “Prohibited Data” to the Game, as Customer
              Data or otherwise, which means any: (a) special categories of data
              enumerated in European Union Regulation 2016/679, Article 9(1) or
              any successor legislation; (b) patient, medical, or other
              protected health information regulated by the Health Insurance
              Portability and Accountability Act (as amended and supplemented)
              (“HIPAA”); (c) credit, debit, or other payment card data or
              financial account information, including bank account numbers; (d)
              credentials granting access to an online account (e.g.
              username/alias plus password); (e) social security numbers,
              driver’s license numbers, or other government identification
              numbers; (f) other information subject to regulation or protection
              under specific laws such as the Children’s Online Privacy
              Protection Act or Gramm-Leach-Bliley Act (or related rules or
              regulations); (g) precise geo-location data; or (h) any data
              similar to the above protected under foreign or domestic laws.
              Therefore, notwithstanding anything else in this Agreement, New
              Relic has no liability for Prohibited Data processed, or High Risk
              Activity-related use, in connection with the Game.
            </li>
            <li>
              THE GAME AND ALL RELATED NEW RELIC SERVICES ARE PROVIDED “AS IS,”
              AND YOU ACKNOWLEDGE THAT YOU USE THE GAME AT YOUR OWN RISK. NEW
              RELIC MAKES NO WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR
              OTHERWISE, INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
              PARTICULAR PURPOSE, TITLE, OR NONINFRINGEMENT. NEW RELIC DOES NOT
              WARRANT THAT USE OF THE GAME WILL BE UNINTERRUPTED OR ERROR-FREE,
              THAT WE WILL REVIEW CUSTOMER DATA FOR ACCURACY, OR THAT WE WILL
              MAINTAIN CUSTOMER DATA WITHOUT LOSS. NEW RELIC IS NOT LIABLE FOR
              ISSUES WITH THE GAME DUE TO DELAYS, FAILURES, OR PROBLEMS INHERENT
              IN THE USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS OR OTHER
              SYSTEMS OUTSIDE OUR CONTROL. NEW RELIC MAKES NO WARRANTIES
              CONCERNING RESULTS TO BE ACHIEVED FROM THE GAME. CUSTOMER MAY HAVE
              OTHER STATUTORY RIGHTS, BUT ANY STATUTORILY REQUIRED WARRANTIES
              WILL BE LIMITED TO THE SHORTEST LEGALLY PERMITTED PERIOD.
            </li>
            <li>
              Neither you nor New Relic grants the other any rights or licenses
              not expressly set out in this Agreement. Except for New Relic’s
              use rights in this Agreement, as between the parties, you retain
              all intellectual property and other rights in Customer Data.
              Except for Customer’s use rights in this Agreement, New Relic
              retains all intellectual property and other rights in the Game and
              related New Relic technology, services, templates, formats, and
              dashboards, including any modifications or improvements to these
              items made by New Relic. If you provide New Relic with feedback or
              suggestions regarding the Game or other New Relic offerings, we
              may use the feedback or suggestions without restriction. To the
              extent New Relic separately makes available other open source
              software (“OSS”) for download, such code is governed by the terms
              of the applicable OSS license.
            </li>
            <li>
              NEW RELIC’S ENTIRE LIABILITY ARISING OUT OF OR RELATED TO THIS
              AGREEMENT WILL BE LIMITED TO FIFTY U.S. DOLLARS ($50). NEW RELIC
              WILL NOT HAVE ANY LIABILITY ARISING OUT OF OR RELATED TO THIS
              AGREEMENT FOR ANY LOSS OF USE, LOST DATA, LOST PROFITS, FAILURE OF
              SECURITY MEASURES, INTERRUPTION OF BUSINESS, OR ANY INDIRECT,
              SPECIAL, PUNITIVE, INCIDENTAL, RELIANCE, OR CONSEQUENTIAL DAMAGES
              OF ANY KIND, EVEN IF INFORMED OF THEIR POSSIBILITY IN ADVANCE. THE
              WAIVERS AND LIMITATIONS IN THIS SECTION APPLY REGARDLESS OF THE
              FORM OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE),
              STRICT LIABILITY, OR OTHERWISE AND WILL SURVIVE AND APPLY EVEN IF
              ANY LIMITED REMEDY IN THIS AGREEMENT FAILS OF ITS ESSENTIAL
              PURPOSE.
            </li>
            <li>
              You will defend New Relic from and against any third-party claim
              to the extent related to or arising from Customer Data, your
              viewing or accessing of the Game, your use of the Game, or your
              breach or alleged breach of (a) this Agreement, or (b) any
              applicable law or regulation. You will hold harmless and indemnify
              New Relic against any damages or costs awarded against New Relic
              (including reasonable attorneys’ fees) or agreed in settlement by
              you resulting from the claim. You may not settle any claim without
              New Relic’s prior written consent if settlement would require the
              indemnified party to admit fault or take or refrain from taking
              any action. New Relic may participate in a claim with its own
              counsel at its own expense.
            </li>
            <li>
              New Relic reserves the right to terminate this Agreement at any
              time, with or without cause, and with or without notice. Upon
              expiration or termination of this Agreement, your access to the
              Game will immediately cease and you must stop sending Customer
              Data to the Game. New Relic may delete Customer Data after
              termination or expiration.
            </li>
            <li>
              You agree to comply with all applicable laws administered by the
              U.S. Commerce Bureau of Industry and Security, U.S. Treasury
              Office of Foreign Assets Control, or other governmental entity
              imposing export controls and trade sanctions (“Export Laws”),
              including designated countries, entities, and persons (“Sanctions
              Targets”); and agree not to directly or indirectly export,
              re-export, or otherwise deliver the Game to a Sanctions Target, or
              broker, finance, or otherwise facilitate any transaction in
              violation of any Export Laws. You represent that you are not a
              Sanctions Target or prohibited from receiving the Game. The Game
              may be used for non-prohibited, entertainment purposes only by
              non-prohibited persons and may not be exported or transferred to
              China or any Sanctions Target.
            </li>
            <li>
              Paragraphs 6 - 14 will survive expiration or termination of this
              Agreement. You may not assign this Agreement without the prior
              written consent of New Relic. Any non-permitted assignment is
              void. This Agreement will bind and inure to the benefit of your
              permitted successors and assigns. This Agreement is governed by
              the laws of the state of California and the United States without
              regard to conflicts of laws provisions or the United Nations
              Convention on the International Sale of Goods. The jurisdiction
              and venue for actions related to this Agreement will be the state
              and United States federal courts located in San Francisco,
              California, and you and New Relic submit to the personal
              jurisdiction of those courts. This Agreement, and any addenda or
              policies otherwise made available by New Relic, is the entire
              agreement between you and New Relic regarding its subject matter
              and supersedes any prior or contemporaneous agreements regarding
              its subject matter. Waivers related to this Agreement must be
              signed by the waiving party’s authorized representative and cannot
              be implied. If any provision of this Agreement is held invalid,
              illegal, or unenforceable, it will be limited to the minimum
              extent necessary so the rest of this Agreement remains in effect.
              New Relic is not liable for any delay or failure to perform any
              obligation under this Agreement due to events beyond its
              reasonable control, such as a strike, blockade, war, act of
              terrorism, riot, Internet or utility failures, refusal of
              government license, or natural disaster.
            </li>
          </ol>
        </Text>
      </Container>
    </PageLayout>
  );
};

export default TermsOfUseScreen;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh; /* Ensure the container takes at least the full viewport height */
  padding: 20px;
  box-sizing: border-box; /* Include padding in the element's total width and height */
`;

const Text = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme }) => theme.colors.layout.p};
  font-size: 1rem;
  max-width: 1000px; /* Set your desired max-width here */
  margin: 0 auto; /* Center the element */

  a:link,
  a:visited {
    color: ${({ theme }) => theme.colors.layout.hc};
  }

  h1 {
    text-align: center;
  }

  p:first-of-type {
    text-align: center;
  }

  ol {
    list-style-type: decimal;
  }

  ol li {
    margin-bottom: 1rem; /* Adjust the spacing value as needed */
  }
`;

const CenterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const TitleContainer = styled(LinesBackground)`
  position: relative;
  margin: 0 0 60px 0;
  padding: 10px 40px;
`;

const Title = styled.h1`
  margin: -10px 0 0 0;
  font-size: 125px;
  font-family: ${({ theme }) => theme.headings.fontFamily};
  white-space: pre-wrap;
  text-transform: uppercase;
  line-height: 95%;
  color: ${({ theme }) => theme.colors.layout.p};
`;
